import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ICompany } from "../../models/ICompany";
import companyImage from "../../assets/img/dream.jpg";
import { Helmet } from "react-helmet";
import Reviews from "../shared/ReviewList";
import ReviewsInfoCard from "../shared/ReviewsInfoCard";
import ReviewAdd from "../shared/ReviewAdd";
import CompanyInformation from "../shared/CompanyInformation";
import CompanyService from "../../services/companyService";

const CompanyDetail: React.FC = () => {
  const [showloading, setShowloading] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>();
  const { lrkNummer } = useParams();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: company?.naam,
    address: {
      "@type": "PostalAddress",
      streetAddress: company?.opvanglocatieAdres,
      addressLocality: company?.opvanglocatieWoonplaats,
      postalCode: company?.opvanglocatiePostcode,
      addressCountry: "NL",
    },
    telephone: company?.contactTelefoon,
    url: `https://opvangreview.nl/reviews/${
      company?.lrkNummer
    }/${encodeURIComponent(company?.naam ?? "")}`,
  };

  useEffect(() => {
    const fetchCompanyDetails = async (lrkNummer: string | undefined) => {
      setShowloading(true);

      try {
        if (lrkNummer != null && lrkNummer !== undefined) {
          // parse number from string
          let lrkNummerParsed: number = Number.parseInt(lrkNummer!);
          var result = await CompanyService.GetCompanyByLrkId(lrkNummerParsed);
          if (result != null && result.success) {
            setCompany(result.payLoad);
          } else {
            alert(
              "Het ophalen van de gegevens niet gelukt. Probeer het later opnieuw."
            );
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setShowloading(false);
      }
    };
    fetchCompanyDetails(lrkNummer);
  }, [lrkNummer]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Opvangreview.nl - ${company?.naam}`}</title>
        <meta
          name="description"
          content={`Find out more about ${company?.naam}, located at ${company?.opvanglocatieAdres}, ${company?.opvanglocatieWoonplaats}.`}
        />
        <link rel="canonical" href={window.location.href} />
        <meta
          property="og:title"
          content={`Opvangreview.nl - ${company?.naam}`}
        />
        <meta
          property="og:description"
          content={`Find out more about ${company?.naam}, located at ${company?.opvanglocatieAdres}, ${company?.opvanglocatieWoonplaats}.`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="URL_TO_COMPANY_IMAGE" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      {/* loading spinner */}
      {showloading && (
        <div className="spinner-border text-primary hero-content" role="status">
          <span className="visually-hidden">Informatie ophalen ...</span>
        </div>
      )}

      <div className="row align-items-center g-lg-5 rounded-bottom ">
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          <img
            className="img-fluid img-thumbnail hero-content"
            src={companyImage}
            alt={company?.naam}
          />
        </div>
        <div className="col-lg-6 col-sm-12 text-center text-lg-start hero-content">
          <h1 className="hero-title mt-2 maxheight-2">{company?.naam}</h1>
          <ReviewsInfoCard paramCompany={company!} />
        </div>
        <div className="herohead-35 hero-contact-bg" />
      </div>

      {/* <div className="after-hero-mobile after-hero" /> */}
      <div className="row custom-row mt-5">
        {/* Reviews */}
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          {company && <Reviews paramCompany={company as ICompany} />}
        </div>

        {/* Company information */}
        <div className="col-lg-6 col-sm-12 text-center text-lg-start">
          <CompanyInformation paramCompany={company as ICompany} />
        </div>

        <div className="col-lg-12 text-center text-lg-start" id="new-review">
          {/* Add review */}
          <ReviewAdd paramCompany={company!} />
        </div>
      </div>
    </>
  );
};

export default CompanyDetail;
